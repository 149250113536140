import { HttpErrorResponse } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import {
  BehaviorSubject,
  Observable,
  defer,
  forkJoin,
  of,
  throwError,
} from 'rxjs'
import {
  catchError,
  map,
  mergeAll,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators'
import { getTimezone } from 'utils/time-utils'
import { environment } from '../../../environments/environment'
import { RestService } from '../rest.service'
import { HubtypeApiService } from './hubtype-api.service'

type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

interface TinybirdApiMethodParams {
  endpoint: string
  queryParams?: { [p: string]: any | any[] }
  version?: string
}

export interface TinybirdApiResponse<T> {
  data: T[]
  meta: { name: keyof T; type: string }[]
  statistics: {
    bytes_read: number
    elapsed: number
    rows_read: number
  }
}

export interface TinybirdExportConfig<T> {
  endpoint: string
  maxElementsToDownload?: number
  columns?: string
}

export const DEFAULT_MAX_ELEMENTS_TO_DOWNLOAD = 200000
const DEFAULT_EXPORT_PAGE_SIZE = 20000

@Injectable({ providedIn: 'root' })
export class TinybirdApiService {
  private BASE_URL = `${environment.tinybirdBaseUrl}`
  private readonly TOKEN_EXPIRATION_TIME_MILLISECONDS = 60000

  private tinybirdToken$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  )
  private tinybirdTokenRequest$: Observable<string> | null = null
  private tinybirdTokenRequestTimeout: any

  private readonly PIPES = [
    'analytics2_projects_kpi_avg_first_response_time',
    'analytics2_projects_kpi_created_cases',
    'conversations_v00_no_anonymize',
    'conversations_filters_v00',
    'analytics2_conversation_metrics',
    'agent_status_log_table_v00',
    'cases_kpis_v00',
    'agents_performance_filters_v00',
    'agents_performance_v00',
    'cases_table_v00',
    'cases_charts_by_project_date',
    'cases_contact_reasons_v00',
    'conversations_automation_v00',
    'conversations_flows_table_v00',
    'bot_interactions_v00',
    'bot_interactions_filters_v00',
  ]

  constructor(
    @Inject('apiService')
    private apiService: HubtypeApiService,
    private restService: RestService
  ) {}

  get<T>({
    endpoint,
    queryParams,
    version = 'v0',
  }: TinybirdApiMethodParams): Observable<TinybirdApiResponse<T>> {
    const method: RestMethod = 'GET'
    const options = {
      method,
      params: {
        ...queryParams,
        tz: getTimezone(),
      },
    }

    return this.request(
      options.method,
      this.buildUrl(this.BASE_URL, endpoint, version),
      options,
      this.PIPES
    )
  }

  clearToken(): void {
    if (this.tinybirdTokenRequestTimeout)
      clearTimeout(this.tinybirdTokenRequestTimeout)

    this.tinybirdToken$.next(null)
  }

  getAllPaginatedResults<T>(
    filters: any,
    config: TinybirdExportConfig<T>
  ): Observable<T[]> {
    const maxElementsToDownload =
      config.maxElementsToDownload || DEFAULT_MAX_ELEMENTS_TO_DOWNLOAD

    const exportPageSize = Math.min(
      maxElementsToDownload,
      DEFAULT_EXPORT_PAGE_SIZE
    )

    // First request to get total pages
    return this.fetchDataForExport<T>(
      {
        ...filters,
        archives_page_size: exportPageSize,
        archives_page: 0,
      },
      config
    ).pipe(
      map(firstPageResponse => {
        if (!firstPageResponse.length) return []

        const totalPages = Math.min(
          firstPageResponse[0].pages_total,
          Math.ceil(maxElementsToDownload / exportPageSize)
        )

        // Create array of requests for remaining pages
        const pageRequests = Array.from({ length: totalPages - 1 }, (_, i) =>
          this.fetchDataForExport<T>(
            {
              ...filters,
              archives_page_size: exportPageSize,
              archives_page: i + 1,
            },
            config
          )
        )

        // Combine first page with parallel requests for remaining pages
        return pageRequests.length
          ? forkJoin(pageRequests).pipe(
              map(responses => firstPageResponse.concat(...responses))
            )
          : of(firstPageResponse)
      }),
      mergeAll()
    )
  }

  private fetchDataForExport<T>(
    filters: any,
    config: TinybirdExportConfig<T>
  ): Observable<(T & { pages_total: number })[]> {
    return this.get<T & { pages_total: number }>({
      endpoint: config.endpoint,
      queryParams: {
        archives_order_by: 'created_at:desc',
        ...(config.columns && { archives_columns: config.columns }),
        ...filters,
      },
    }).pipe(map(response => response.data))
  }

  private buildUrl(baseUrl: string, endpoint: string, version: string): string {
    return `${baseUrl}/${version}/pipes${endpoint}`
  }

  private request(
    method: RestMethod,
    url: string,
    options: { [param: string]: any },
    pipes: string[]
  ): Observable<any> {
    const request = this.getTinybirdToken(pipes).pipe(
      switchMap(token =>
        this.restService.request(method, url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        })
      )
    )

    return request.pipe(
      catchError(error => {
        if (this.isTokenExpiredError(error)) {
          this.clearToken()
          return request.pipe(
            catchError(secondError => throwError(() => secondError))
          )
        } else {
          return throwError(() => error)
        }
      })
    )
  }

  private isTokenExpiredError(response: HttpErrorResponse): boolean {
    return (
      response.status === 403 &&
      response.error.error.includes('invalid authentication token')
    )
  }

  private getTinybirdToken(pipes: string[]): Observable<string> {
    return defer(() => {
      if (this.tinybirdToken$.value) {
        // Reusing the token if it's still valid
        return of(this.tinybirdToken$.value)
      }

      // No token and no request in progress (to avoid multiple token requests)
      if (!this.tinybirdTokenRequest$) {
        this.tinybirdTokenRequest$ = this.requestTinybirdToken(pipes).pipe(
          tap(newToken => {
            // Saving token and starting timeout to clear it (expired)
            this.tinybirdToken$.next(newToken)
            this.tinybirdTokenRequest$ = null
            this.tinybirdTokenRequestTimeout = setTimeout(
              () => this.clearToken(),
              this.TOKEN_EXPIRATION_TIME_MILLISECONDS
            )
          }),
          shareReplay({ refCount: true, bufferSize: 1 })
        )
      }

      return this.tinybirdTokenRequest$
    })
  }

  private requestTinybirdToken(pipes: string[]): Observable<string> {
    return this.apiService
      .postV2({
        endpoint: '/analytics/auth/create_jwt_token/',
        version: 'v2',
        data: { pipes },
      })
      .pipe(map(response => response.token))
  }
}
